import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import spm from "../Assets/Spm.jpg";
function Spm(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={spm} bg_prod_img={spm} heading="Special Purpose Machine (SPM)" text="Special Purpose Machines (SPM) are those machine which are not available off the shelf. These are not covered in standard manufacturing programs. Therefore those have to be designed & tailor made as Per the customer’s specific requirements."></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default Spm;