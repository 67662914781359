import "./SubServicesStyles.css";
import"./SubServicesData";
import "./OurClientStyles.css";
import oip from "../Assets/OIP.jpg"
import mahindra from "../Assets/R.png"
import Iton from "../Assets/itonLogo.png"
import fiat from "../Assets/tataFiat.jpg";
import tata from "../Assets/TataMotors.jpg";
import ncl from "../Assets/ncl_logo.png";
import Navbar  from "./Navbar";
function OurClients(){
    return(
        <>
        <Navbar></Navbar>
        
      <div className="subService">
            <h1>Our Clients </h1>
  
        </div>


  <div className="container">
        <div className="gallery">
        <img src={oip}alt="image"></img>
        <img src={mahindra}alt="image"></img>
        <img src={Iton}alt="image"></img>
        <img src={fiat}alt="image"></img>
        <img src={tata}alt="image"></img>
        <img src={ncl}alt="image"></img>
     </div> 
     </div>

        </>
    );
}
export default OurClients