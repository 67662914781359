import { Component } from "react";
import "./ProfileStyles.css";
import Profile0 from "../Assets/iot_profile_bgjpg.jpg"
import Profile1 from "../Assets/our_mission.webp"
class ProfileData extends Component{
    render(){
        return(
         <>
         
<div className="first-des">
    <div className="des-text">
       <h2>{this.props.heading}</h2>
       <p>{this.props.text}</p>
    </div>

    <div className="image">
        <img alt="img" src={this.props.img1} ></img>
        <img alt="img" src={this.props.img2} ></img>

    </div>
</div>

         
         </>   
        )
    }
}
export default ProfileData


