import "./IotDashStyles.css";
function Iot_dashboard_data(props){
    return(
        
        <>
         <div className="row">
           
        <a href={props.bg_prod_img}>
            <div className="left">
             <img src={props.image}alt="image"></img>
            </div>
            </a>
               <div className="right">
                <div className="content">
                    <span>{props.cName}</span>
                   <h4>{props.heading}</h4>
                    <p>{props.text}</p>
                </div>
              </div>

         </div>

        </>

        
        

        
             

    )
}
export default Iot_dashboard_data