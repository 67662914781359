import "./AboutUsStyles.css";

function AboutUs(){
    return(
        <div className="about-container">
            <h1>Our History</h1>
            <p>lorem unications or device-to-cloud communications. The gateway is typically a hardware device housing application software that performs essential tasks. At its most basic level, the gateway facilitates the connections between different data sources and destinations.A simple way to conceive of an IoT Gateway is to compar  </p>

            <h1>Our Mission</h1>
            <p>lorem unications or device-to-cloud communications. The gateway is typically a hardware device housing application software that performs essential tasks. At its most basic level, the gateway facilitates the connections between different data sources and destinations.A simple way to conceive of an IoT Gateway is to compar  </p>

            <h1>Our Vision</h1>
            <p>lorem unications or device-to-cloud communications. The gateway is typically a hardware device housing application software that performs essential tasks. At its most basic level, the gateway facilitates the connections between different data sources and destinations.A simple way to conceive of an IoT Gateway is to compar  </p>
        </div>


    )
}
export default AboutUs