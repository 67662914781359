import { Hero } from "../componenets/Hero";
import Navbar from "../componenets/Navbar";
import Footer from "../componenets/Footer.js";
import AboutUs from"../componenets/AboutUs.js";
import AboutImg from"../Assets/big-data.webp";

function About(){
    return(
        <>
           <Navbar/>
    {/* <Route path="/about" element={<About/>}/> */}
    <Hero cName="hero-mid" heroImg={AboutImg} title=""></Hero>
    <AboutUs></AboutUs>
    <Footer></Footer>
    </>
    )
    }
    export default About;