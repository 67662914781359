import "./FooterStyles.css";
import { NavLink } from "react-router-dom";
// import MapContainer from "./MapContainer";
const Footer = () => {
   
    return (
        <>
            <div className="footer">
                <div className="top">
                    <div className="">
                        <h1>EmbOne Technology</h1>
                        {/* <NavLink to ="/">
                <img src="./images/logo.png" alt="logo" className="footer-logo"/>
                </NavLink> */}
                        <p></p>
                    </div>
                    
                    {/* social media icon */}
                    <div className="">
                    <a href="tel:+917972418826">
                        <i className="fa-solid fa-phone">
                            </i></a>

                        <a href="mailto:info.embone@gmail.com">
                            <i class="fa-solid fa-envelope"></i>
                        </a>
                        <a href="https://www.linkedin.com/in/nilesh-pardhe/?originalSubdomain=in" target="_blank" rel="noopaper noreference">
                            <i className="fa-brands fa-linkedin"></i>
                        </a>
                    </div>

                </div>

                <div className="bottom ">
                    <div className="bottom_nav_content ">
                        <NavLink to="/">
                            <img src="./images/logo.png" alt="logo" className="footer-logo" />
                        </NavLink>
                        <h5><b></b>GO TO</h5>
                        <NavLink to="/"><i className="fa-solid fa-house-user"></i>Home</NavLink>
                        <NavLink to="/subProduct"><i className="fa-solid fa-circle-info"></i>Products</NavLink>
                        <NavLink to="/subServices"><i className="fa-brands fa-product-hunt"></i>Our Offerings</NavLink>
                        <NavLink to="/ourClients"><i className="fa-solid fa-users"></i>Clients</NavLink>
                        <NavLink to="/career"><i className="fa-solid fa-user-tie"></i>Career</NavLink>

                        {/* <NavLink to ="/">
                <img src="./images/logo.png" alt="logo" className="footer-logo"/>
                </NavLink> */}
                    </div>

                    <div className="bottom_prod_list">
                        <h4>Solutions</h4>
                        <NavLink to="/fuel_cell_test">Fuel Cell Testing</NavLink>
                        <NavLink to="/ssr_module">Solenoid Driver(SSR)</NavLink>
                        <NavLink to="/scada_dashboard"> SCADA System</NavLink>
                        <NavLink to="/ni_labview">NI LabVIEW</NavLink>
                        <NavLink to="/qt">QT</NavLink>
                        <NavLink to="/spm">SPM</NavLink>
                        <NavLink to="/custom_dev">Custom development</NavLink>
                        <NavLink to="/Camera_vi">Camera Vision System</NavLink>
                        <NavLink to="/iot_gateway">IoT Gateways</NavLink>
                        <NavLink to="/iot_enables">IoT Enabled Devices</NavLink>

                    </div>

                    <div className="address ">
                        <h4>EmbOne Technologies,Pune</h4>
                        <p>202,Shankar plaza,Bakaji Corner,<br />
                            Bavdhan, Pune-411021, Maharashtra,<br />
                            INDIA.
                        </p>
                        <a href="/"><i className="fa-solid fa-envelope"></i>info.embone@gmail.com</a>
                        <a href="/"><i className="fa-solid fa-phone"></i>+917972418826</a>
                        <div className="indiamart">
                            <a href="https://www.indiamart.com/embone-technologies/" className="indiamart" target="_blank" rel="noopaper noreference">Our IndiaMART Website</a></div>
                    </div>
                    <div className="location">
                        <h4>Share Your Resume With Us..</h4>
                        <a href="/"><i className="fa-solid fa-envelope"></i>info.embone@gmail.com</a>
                        <a href="/"><i className="fa-solid fa-phone"></i>+917972418826</a>

                          {/* <iframe async src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.255153779118!2d73.76857767470779!3d18.51736836927304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2950c54ab274f%3A0xdce563872011cead!2sEmbone%20Technologies!5e0!3m2!1sen!2sin!4v1707243232050!5m2!1sen!2sin"
                 width="600" 
                 height="450" 
                 style={{border:"0"}}
                 allowFullScreen=""
                 loading="lazy" 
                 referrerPolicy="no-referrer-when-downgrade"></iframe>  */}
                    </div>
                </div>
            </div>
            <div className="container-fluid copywrite">
                © 2022 Copyright : <a href="http://embonetech.in/" target="_blank" rel="noopaper noreference">embonetech.in</a>
            </div>
            
        </>
        
    )
}

export default Footer