import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import machine_monitoring_ from "../Assets/Machinemonitoring.png";
function Machine_monitoring(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={machine_monitoring_} bg_prod_img={machine_monitoring_} heading="Machine Monitoring" text="Supervisory control and data acquisition (SCADA) is a control system architecture comprising computers, networked data communications and graphical user interfaces for high-level supervision of machines and processes.It also covers sensors and other devices, such as programmable logic controllers, which interface with process plant or machinery."></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default Machine_monitoring;