import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import iot_gateway_ from "../Assets/IoTgateway.png";
function Machine_monitoring(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={iot_gateway_} bg_prod_img={iot_gateway_} heading="
IoT Gateways" text="An IoT Gateway is a solution for enabling IoT communication, usually device -to-device communications or device-to-cloud communications. The gateway is typically a hardware device housing application software that performs essential tasks. At its most basic level, the gateway facilitates the connections between different data sources and destinations.

A simple way to conceive of an IoT Gateway is to compare it to your home or office network router or gateway. Such a gateway facilitates communication between your devices, maintains security and provides an admin interface where you can perform basic functions. An IoT Gateway does this and much more."></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default Machine_monitoring;