import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import heat_flow_meter_ from "../Assets/HeatFlowMeter.png";
function BITools(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={heat_flow_meter_} bg_prod_img={heat_flow_meter_} heading="BI Tools" text="The solution enables exploring and analysing huge volumes of data generated by the connected Internet of Things (IoT) devices such as sensors on manufacturing equipment, pipelines, weather stations, smart meters, delivery trucks, etc."></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default BITools;