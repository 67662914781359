import { Hero } from "../componenets/Hero";
import Navbar from "../componenets/Navbar";
import Footer from "../componenets/Footer.js";
import SubServices from "../componenets/SubServices.js";
import servicesImg from"../Assets/big-data.webp";

function Services(){
    return(
        <>
        <Navbar/>
       <Hero cName="hero-mid" heroImg={servicesImg}></Hero>
       <SubServices></SubServices>
       <Footer></Footer>
       </>
    )
    }
    export default Services;