import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import ssr_module from "../Assets/SSRModule.png";
import ssr_module_full_img from "../Assets/SSRModuleFullimg.png";

function SSR_module(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={ssr_module} bg_prod_img={ssr_module_full_img} heading="SSR module(Solenoid Driver):" text="A solid-state relay is an essential tool for engineers and technicians seeking accurate and durable control solutions in a wide range of industrial and commercial applications.
Solid State Relay is similar to a mechanic relay where it can be controlled through a digital signal. Solid State Relay generates no noise and has a much longer lifespan compared to the traditional mechanic relay.


"></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default SSR_module;