import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import high_speed from "../Assets/Highspeedopto.png";
import high_speed_full_img from "../Assets/HighSpeedOptoFillImg.png";

function High_speed_opto(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={high_speed} bg_prod_img={high_speed_full_img} heading="High Speed Opto module:" text="This is an Optocoupler Isolation Board. To achieve the control signal and the control signal isolation, you can directly use the microcontroller/PLC or other devices IO port to achieve voltage isolation control.

"></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default High_speed_opto;