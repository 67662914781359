import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import do_mod from "../Assets/DOmodule.png";
import do_mod_full_img from "../Assets/RemoteDOFullImg.png";

function DI_module(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={do_mod} bg_prod_img={do_mod_full_img} heading="Remote Digital Output (DO) Module " text="Remote Digital Output Module with a RJ45 (Ethernet Modbus communication) and Wi-Fi interface. The digital outputs can be used operation switches of various types of control circuits. In addition to controlling output for lamps, LEDs, 7-segment displays, and relays, etc.

"></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default DI_module;