import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import fuel_cell from "../Assets/fuelcelltesting.png";
function Fuel_cell_test(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={fuel_cell} bg_prod_img={fuel_cell} heading="Fuel Cell Testing" text="Single / Multiple or stack hydrogen cell test station software development will be provided.
Heater, Pressure sensors, Backpressures, MFC, Temperature sensors,PID,02 and H2 sensors, Leakage sensors, Rh sensors, Chiller will be interfaced.
Analysis graphs and control screens will be provided.
Test technique CV, CC, LSV and polarization will be optional.
CVM and SVM will be optional.
DC power supply interfacing and control mechanisms.
Data log and reporting.
Safety interlocking and Auto and Manual test execution.
As per user customization will be provided
IOT Will be Optional

"></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default Fuel_cell_test;