import Navbar from "./Navbar";
// import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import custom_dev from "../Assets/customdevop.png";
function Custom_dev(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={custom_dev} bg_prod_img={custom_dev} heading="Custom Development" text="Embedded systems refer to electronic products that are based on microcontrollers. They possess computational logic, memories, communications, I/O peripherals and are usually used for a specific function. A washing machine is an embedded system. The same goes for your smartphone, security access system, vending machine, and possibly any ‘intelligent’ appliances.Embedded systems design is the process where hardware and firmware designers come together to build embedded systems from scratch. This involves PCB design, where the necessary components are connected to build functional circuits. To bring the electronics to life,the firmware is coded and then programmed into the microcontroller."></Iot_dashboard_data>
       {/* <Footer/> */}
    </>
    )
    }
    export default Custom_dev;