import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";
import "./CardStyles.css";

function Card() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:3,
    slidesToScroll: 1,
    responsive:[
      {
        breakpoint:1024,
        settings:{
          slidesToShow:1,
          slidesToScroll: 1,

        }
      }
    ]
  };
  return (
    <>
        <div className="card_prod_name ">
            <h1>Our Products </h1>
        </div> 
        <div className="slider">
        <Slider {...settings}>
        {data.map(d => (
< div key={d.id}>
<div className="card w-100">
  <div className="img_div">  
  <img src={d.img} className="card-img-top" alt="..."/>
  </div>
  <div className="card-body">
    <h5 className="card-title">{d.name}</h5>
   <NavLink to= {d.url}>View</NavLink>
  </div>
</div>
</div>

))}
</Slider>
</div>

    </>
  );
}

const data = [
  {
    id:0,
    name: "Fuel Cell Testing",
    img: "/images/fuelcelltesting.png",
    url: "/fuel_cell_test"
  },
  {
    id:1,
    name: "Remote Solenoid Driver (SSR)",
    img: "/images/remotessr.png",
    url: "/Remote_SSR"
  },
  {
    id:2,
    name: "IOT Gateway",
    img: "/images/IoTgateway.png",
    url: "/iot_gateway"
  },
  {
    id:3,
    name: "IOT Dashboard",
    img: "/images/IoTdashboard.jpg",
    url: "/iot_dashboard"
  },
  {
    id:4,
    name: "High Speed Opto module",
    img: "/images/Highspeedopto.png",
    url: "/high_speed_opto"
  },
 
  
];

export default Card;

















