import "./SubProductStyles.css";
import"./SubProductData";
import Navbar from "./Navbar";
import Footer from "./Footer";
import SubProductData from "./SubProductData";
import iot_dashboard from "../Assets/IoTdashboard.jpg";
import industrial from "../Assets/Industrial4.0.jpg";
import iot_gateway from "../Assets/IoTgateway.png";
import spcDashboard from"../Assets/SPCdashboard.png";
import machine_monitoring from"../Assets/Machinemonitoring.png";
import heat_flow_meter from"../Assets/HeatFlowMeter.png";
import fuel_cell_test from"../Assets/fuelcelltesting.png";
import Solenoid_img from"../Assets/SolenoidDriver.png";
import ssr_solenoid_img from"../Assets/SSRModule.png";
import hight_speed_img from"../Assets/Highspeedopto.png";
import remote_di_img from "../Assets/DImodule.png";
import remote_do_img from "../Assets/DOmodule.png"
import pwn_gen_img from"../Assets/pwnGenerator.png";
import remote_freq_img  from "../Assets/remoteFrequency.png";
import plc_automation_ from "../Assets/HeatFlowMeter.png";
function SubProduct(){
    return(
        <>


<Navbar/>
        <div className="subproduct">
            <h1>Our Products </h1>
            {/* <p>Qt is a widget toolkit for creating graphical user interfaces as well as </p> */}
        </div>

        <div className="subproduct_card">

            <SubProductData image={iot_dashboard} heading="IoT Dashboard" text="" url="/iot_dashboard">
            </SubProductData>


            <SubProductData image={industrial} heading="Industrial Internet of Things (IIoT)" 
            url={"/IIot_data"}>
            </SubProductData>

            <SubProductData image={iot_gateway} heading="IOT Gateway" url="/iot_gateway">
            </SubProductData>

          </div>

          <div className="subproduct_card">

            <SubProductData image={spcDashboard} heading="SPC dashboard"url="/spcDashboard"></SubProductData>
            
            <SubProductData image={machine_monitoring} heading="Machine Monitoring" url="/machine_monitoring"></SubProductData>

            <SubProductData image={heat_flow_meter} heading="BI Tools" url="/biTools"></SubProductData>
            

          </div>

          <div className="subproduct_card">

          <SubProductData image={fuel_cell_test} heading="Fuel Cell Testing" url="/fuel_cell_test"></SubProductData>

            <SubProductData image={Solenoid_img} heading="Remote Solenoid Driver (SSR) module" url="/remote_SSR"></SubProductData>

            <SubProductData image={ssr_solenoid_img} heading="SSR module(Solenoid Driver)" url="/ssr_module"></SubProductData>

</div>

<div className="subproduct_card">

          <SubProductData image={hight_speed_img} heading="High Speed Opto module:" url="/high_speed_opto"></SubProductData>

            <SubProductData image={remote_di_img} heading="Remote Digital Input (DI) Module" url="/remote_di"></SubProductData>

            <SubProductData image={remote_do_img} heading="Remote Digital Output (DO) Module " url="/remote_do_module"></SubProductData>
            
</div>

<div className="subproduct_card">

          <SubProductData image={pwn_gen_img} heading="Remote PWM Generator Module" url="/pwn_generator"></SubProductData>

            <SubProductData image={remote_freq_img} heading="Remote Frequency Measuring Module" url="/remote_frequency"></SubProductData>

            <SubProductData image={plc_automation_} heading="PLC Based Automation " url="/plc_automation"></SubProductData>
            
</div>

<Footer></Footer>

        </>
    );
}
export default SubProduct