import "./NavbarStyles.css";
import { NavLink } from "react-router-dom";
import logo from "../Assets/logo.png"
function Navbar(){

    return(
        <>
<nav className="navbar navbar-expand-lg navbar-light NavbarItems">
  <div className="container-fluid">
         <NavLink to ="/">
           <img src={logo} alt="logo" className="navbar-logo"/>
        </NavLink>    
     <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse  justify-content-center" id="navbarNavDropdown">
      <ul className="navbar-nav ">

         {/* Home */}
      <li className="nav-item    ">
        <NavLink className="nav-links"  to="/">
        <i className="fa-solid fa-house-user"></i> Home
        </NavLink>
      </li>


 {/* products */}
        <li className="nav-item dropdown">
          <NavLink className="nav-links " to="/subProduct" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fa-brands fa-product-hunt"></i>Products
          </NavLink>
          <div  className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <ul>
          <li><NavLink className="dropdown-item first_menu">IOT Appliance</NavLink></li>
          <li><NavLink className="dropdown-item" to="/fuel_cell_test">Fuel Cell Testing</NavLink></li>
          <li><NavLink className="dropdown-item" to="/iiot_data">Industrial 4.0</NavLink></li>
         <li><NavLink className="dropdown-item" to="/iot_gateway">Iot Gateway</NavLink></li>
         <li><NavLink className="dropdown-item" to="/iot_dashboard">Iot Dashboard</NavLink></li>
         <li><NavLink className="dropdown-item" to="/biTools">BI Tools</NavLink></li>
         <li><NavLink className="dropdown-item" to="/spcDashboard">SPC Dashboard</NavLink></li>
         <li><NavLink className="dropdown-item" to="/machine_monitoring">Machine Monitoring</NavLink></li>
      </ul>

            <ul>
              <li><NavLink className="dropdown-item first_menu" href="#">Remote Interface Cards</NavLink></li>
              <li><NavLink className="dropdown-item" to="/remote_SSR">Remote Solenoid Driver(SSR)</NavLink></li>
              <li><NavLink className="dropdown-item" to="/remote_di">Remote Digital Input (DI)</NavLink></li>
              <li><NavLink className="dropdown-item" to="/remote_do_module">Remote Digital Output (DO) </NavLink></li>
              <li><NavLink className="dropdown-item" to="/pwn_generator">Remote PWM Generator </NavLink></li>
              <li><NavLink className="dropdown-item" to="/remote_frequency">Remote Frequency Measuring </NavLink></li>

            </ul>
            <ul>
            <li><NavLink className="dropdown-item first_menu" href="#">Interface Cards</NavLink></li>
              <li><NavLink className="dropdown-item" to="/ssr_module">Solenoid Driver(SSR)</NavLink></li>
              <li><NavLink className="dropdown-item" to="/high_speed_opto">High Speed Opto module</NavLink></li>

            </ul>
            
          </div>
        </li>

 {/* Services */}
 <li className="nav-item dropdown">
          <NavLink className="nav-links " to="/subServices" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fa-solid fa-circle-info"></i>Our Offerings
          </NavLink>
          <div  className="dropdown-menu serv" aria-labelledby="navbarDropdownMenuLink">
            <ul>
              <li><NavLink className="dropdown-item first_menu">Industrial Automation</NavLink></li>
              <li><NavLink className="dropdown-item" to="/fuel_cell_test">Fuel Cell Testing</NavLink></li>
              <li><NavLink className="dropdown-item" to="/scada_dashboard">SCADA Dashboard</NavLink></li>
              <li><NavLink className="dropdown-item" to="/ni_labview">NI Labview</NavLink></li>
              <li><NavLink className="dropdown-item" to="/qt">QT</NavLink></li>
              <li><NavLink className="dropdown-item" to="/plc_automation">PLC Based Automation</NavLink></li>
              <li><NavLink className="dropdown-item" to="/camera_vi">Camera VISION System</NavLink></li>
              <li><NavLink className="dropdown-item" to="/spm">SPM</NavLink></li>            

              </ul>
            <ul>
            <li><NavLink className="dropdown-item first_menu">Embeded Design</NavLink></li>
            <li><NavLink className="dropdown-item" to="/custom_dev">Custom Development</NavLink></li>
            <li><NavLink className="dropdown-item" to="/iot_enables">IOT Enables</NavLink></li>            
             <li><NavLink className="dropdown-item" to="/fuel_cell_test">Fuel Cell Testing</NavLink></li>
              <li><NavLink className="dropdown-item" to="/ssr_module">Solenoid Driver(SSR)</NavLink></li>
              <li><NavLink className="dropdown-item" to="/high_speed_opto">High Speed Opto module</NavLink></li>


            </ul>
            <ul><li><NavLink className="dropdown-item first_menu" >Remote Appliance</NavLink></li>
              <li><NavLink className="dropdown-item" to="/remote_frequency">Remote Frequency Measuring module</NavLink></li>
              <li><NavLink className="dropdown-item" to="/remote_SSR">Remote Solenoid Driver(SSR)</NavLink></li>
              <li><NavLink className="dropdown-item" to="/remote_di">Remote Digital Input (DI)</NavLink></li>
              <li><NavLink className="dropdown-item" to="/remote_do_module">Remote Digital Output (DO) </NavLink></li>
              <li><NavLink className="dropdown-item" to="/pwn_generator">Remote PWM Generator </NavLink></li>

            </ul>
            
          </div>
        </li>

{/* Career */}
<li className="nav-item">
        <NavLink className="nav-links"  to="/career">
        <i className="fa-solid fa-user-tie"></i>Career
          </NavLink>
        </li>

{/* contact */}
<li className="nav-item">
        <NavLink className="nav-links"  to="/contact">
        <i className="fa-solid fa-address-book"></i> Contact
          </NavLink>
        </li>

       

      </ul>
    </div>
  </div>
</nav>
 </>
    )
}export default Navbar;

























































