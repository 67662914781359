import "./ContactFormStyles.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./CareerStyles.css";
function ContactForm() {
  return (
    <>
      <Navbar />


      <div className="container hire_tag">
        <h1 className="">How we hire ?</h1>
        <h5>Share your resume on </h5>
        <i className="fa-solid fa-envelope"></i> info.embone@gmail.com
      </div>


      <div className="container">
        <div className="gallery ">

          <div className="wrap_div ">
            <i className="fa-solid fa-envelope icon"></i>
            <div className="text_" ><h2>Apply</h2></div>
            <p>Share Your Resume<br /> with us</p>
          </div>

          <div className="wrap_div">
            <i className="fa-solid fa-clipboard-question icon"></i>
            <div className="text_"><h2>Interview</h2></div>
            <p>A recruiter will reach out <br />to schedule a interview</p>
          </div>




          <div className="wrap_div">
            <i className="fa-solid fa-clock icon"></i>
            <div className="text_"><h2>Decision</h2></div>
            <p>If selected..recruiter<br /> will present a job offer</p>
          </div>



          <div className="wrap_div">
            <div><i className="fa-solid fa-face-smile icon"></i>
              <div className="text_"><h2>Congratulations</h2></div>
              <p>Welcome to<br /> EmbOne Technologies</p>
            </div>

          </div>
        </div>

      </div>

      <Footer />
    </>
  )
}
export default ContactForm


