import "./ContactFormStyles.css";
import { NavLink } from "react-router-dom";
import logo_footer from "../Assets/logo.png"
function ContactForm(){
    return(
        <> 
        <div className=" row ">
            <div className="left_container">
            <NavLink to ="/">
                <img src={logo_footer} alt="logo" className="img-logo"/>
                </NavLink>            
                </div>
                
              <div className="right">
                <div className="content">
                   <h4>Reach Us</h4>
                <h3>EmbOne Technologies,Pune</h3>
                <p className="footer_addr">202,Shankar plaza,Bakaji Corner,<br/>
                   Bavdhan, Pune-411021, Maharashtra,<br/>
                   INDIA.
                </p>
                <div className="content">
                <a href="https://www.linkedin.com/in/nilesh-pardhe/" target="_blank" rel="noopaper noreference"><i className="fa-brands fa-linkedin"></i> Nilesh Pardhe(Propritor)</a><br/>
                <a href="/"><i className="fa-solid fa-envelope"></i> info.embone@gmail.com</a><br/>               
                <a href="/"><i className="fa-solid fa-phone number"></i> +917972418826    </a><br/>                
                <div className="india_mart"><a href="https://www.indiamart.com/embone-technologies/" target="_blank" rel="noopaper noreference"> <i className="fa-solid fa-link"></i> Our IndiaMART Website</a></div>
           </div>
                </div>
              </div>

         </div>

        </>
    )
}
export default ContactForm


