import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import remote_freq from "../Assets/remoteFrequency.png";
import remote_freq_full_img from "../Assets/Frequencymesauringfull_img.png";

function Remote_frequency(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={remote_freq} bg_prod_img={remote_freq_full_img} heading="Remote Frequency Measuring Module" text="Remote Frequency measuring/counter Module with a RJ45 and Wi-Fi interface. Interface with the PLC/microcontroller to read high speed pulse, Low speed pulse. It is based on Ethernet Modbus communication.

"></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default Remote_frequency;