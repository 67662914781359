import { Hero } from "../componenets/Hero";
import Navbar from "../componenets/Navbar";
import Profile from "../componenets/Profile";
import Footer from "../componenets/Footer.js";
import HeroImg from"../Assets/big-data.webp";
import HomeServices from "../componenets/HomeServices.js";
import OurClients from "../componenets/OurClients.js";
import Card from "../componenets/Card.js";
// import SubProduct from "../componenets/SubProduct.js";
// import SubServices from "../componenets/SubServices.js";

function Home(){
return(
    <>
    <Navbar/>

   <Hero cName="hero"heroImg={HeroImg}></Hero> 

   <Profile></Profile>

   <Card></Card>
   <HomeServices></HomeServices>
   <OurClients></OurClients>
    <Footer></Footer>
    </>
)
}
export default Home;