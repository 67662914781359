import { Hero } from "../componenets/Hero";
import Navbar from "../componenets/Navbar";
import Footer from "../componenets/Footer.js";
import SubProduct from "../componenets/SubProduct.js";
import productImg from"../Assets/big-data.webp";

function Product(){
    return(
        <>
        <Navbar/>
       <Hero cName="hero-mid" heroImg={productImg} title=""></Hero>

       <SubProduct></SubProduct>
       <Footer></Footer>
       </>
    )
    }
    export default Product;