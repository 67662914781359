import Profile0 from "../Assets/iot_profile_bgjpg.jpg"
import Profile1 from "../Assets/our_mission.webp"
import ProfileData from "./ProfileData"
import "./ProfileStyles.css"

const Profile=()=>{
    return(
<div className="profile">
<h5>We Build  </h5>
<h2>SMART IOT ENABLED APPLIANCES</h2>
 
 <ProfileData
  heading="Profile" 
 text="Our mission is to Empower Machines Beyond Optimised Novel Engineering using Electronics, Embedded Systems, the Internet Of Things (IoT), and the Power of Automation.
 
 We make machines smart using automation systems and connectivity, communication, software analytics, dashboarding, and reporting to provide a complete solution to overcome those long, odd hours, strict deadlines, and operational challenges.
 
 We offer NI-LabVIEW, Qt, Matlab, VB, C, and CPP-based application development for PC-based Automation. We have an expert team for R&D in Custom Embedded Systems, Analog and Digital circuit design and development."
img1={Profile0}
img2={Profile1}
 
 ></ProfileData>
 


</div>
    )

}
export default Profile