import React from "react";
import{Route,Routes} from "react-router-dom";
import Home from "./routes/Home";
import About from "./routes/About";
import Services from "./routes/Services";
import Products from "./routes/Products";
import Contact from "./routes/Contact";
import Career from "./componenets/Career";
import Iot_dashboard from "./componenets/Iot_dashboard";
import Machine_monitoring from "./componenets/Machine_monitoring";
import IIot_data from "./componenets/IIot_data";
import Iot_gateway from "./componenets/Iot_gateway";
import BITools from "./componenets/BITools";
import SpcDashboard from "./componenets/SpcDashboard";
import Scada_dashboard from "./componenets/Scada_dashboard";
import Ni_labview from "./componenets/Ni_labview";
import QT from "./componenets/Qt";
import Plc_automation from "./componenets/Plc_automation";
import Camera_vi from "./componenets/Camera_vi";
import Spm from "./componenets/Spm";
import Custom_dev from "./componenets/Custom_dev";
import Iot_enables from "./componenets/Iot_enables";
import Fuel_cell_test from "./componenets/Fuel_cell_test";
import ScrollToTop from "./componenets/ScrollToTop";
import Remote_SSR from "./componenets/Remote_SSR";
import SSR_module from "./componenets/SSR_module";
import High_speed_opto from "./componenets/High_speed_opto";
import DI_module from "./componenets/DI_module";
import DO_module from "./componenets/DO_module";
import Pwm_generator from "./componenets/Pwm_generator";
import Remote_frequency from "./componenets/Remote_frequency";
import SubProduct from "./componenets/SubProduct";
import SubServices from "./componenets/SubServices";
import OurClients from "./componenets/OurClients";
import Card from "./componenets/Card";

export default function App(){
  return(
    <>
    <div className="App">
    <ScrollToTop/>

      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/products" element={<Products/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/career" element={<Career/>}/>
        <Route path="/subProduct" element={<SubProduct/>}/>
        <Route path="/subServices" element={<SubServices/>}/>
        <Route path="/ourClients" element={<OurClients/>}/>
        <Route path="/iot_dashboard" element={<Iot_dashboard/>}/>
        <Route path="/machine_monitoring" element={<Machine_monitoring/>}/>
        <Route path="/iiot_data" element={<IIot_data />}/>
        <Route path="/iot_gateway" element={<Iot_gateway />}/>
        <Route path="/biTools" element={<BITools/>}/>
        <Route path="/spcDashboard" element={<SpcDashboard/>}/>
        <Route path="/scada_dashboard" element={<Scada_dashboard/>}/>
        <Route path="/ni_labview" element={<Ni_labview/>}/>
        <Route path="/qt" element={<QT/>}/>
        <Route path="/plc_automation" element={<Plc_automation/>}/>
        <Route path="/camera_vi" element={<Camera_vi/>}/>
        <Route path="/spm" element={<Spm/>}/>
        <Route path="/custom_dev" element={<Custom_dev/>}/>
        <Route path="/iot_enables" element={<Iot_enables/>}/>
        <Route path="/fuel_cell_test" element={<Fuel_cell_test/>}/>
        <Route path="/remote_SSR" element={<Remote_SSR/>}/>
        <Route path="/ssr_module" element={<SSR_module/>}/>
        <Route path="/high_speed_opto" element={<High_speed_opto/>}/>
        <Route path="/remote_di" element={<DI_module/>}/>
        <Route path="/remote_do_module" element={<DO_module/>}/>
        <Route path="/pwn_generator" element={<Pwm_generator/>}/>
        <Route path="/remote_frequency" element={<Remote_frequency/>}/>
        <Route path="/card" element={<Card/>}/>
        <Route path="*" element={<Home/>} />
       



      </Routes>
    {/* <Navbar></Navbar> */}
    </div>
    
    </>
  )
}