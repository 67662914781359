import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import camera_vis from "../Assets/CameraVisionSystem.png";
function Camera_vi(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={camera_vis} bg_prod_img={camera_vis} heading="Camera VISION Systems" text="Machine Vision products include vision systems, smart cameras, frame grabbers, and vision interfaces designed for a wide range of applications, such as assembly verification, object tracking and vision-guided robotics."></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default Camera_vi;