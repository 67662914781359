import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import Pwm_gen from "../Assets/pwnGenerator.png";
import Pwm_gen_full_img from "../Assets/pwnGeneratorFullImg.png";

function Pwm_generator(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={Pwm_gen} bg_prod_img={Pwm_gen_full_img} heading="Remote PWM Generator Module" text="Remote PWM (pulse with modulation) Module with a RJ45 and Wi-Fi interface, Ethernet Modbus/socket communication. PWM module that generates a pulse signal with a programmable frequency and duty cycle using PLC/controller. It is used to control motors, to power LEDs, drive buzzer with different loudness, etc.

"></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default Pwm_generator;