import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import Scadaimg from "../Assets/SCADAsystem.png";
function Scada_dashboard(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={Scadaimg} bg_prod_img={Scadaimg} heading="SCADA System" text="Supervisory control and data acquisition (SCADA) is a control system architecture comprising computers, networked data communications and graphical user interfaces for high-level supervision of machines and processes. It also covers sensors and other devices,such as programmable logic controllers, which interface with process plant or machinery."></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default Scada_dashboard;