import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import plc_auto from "../Assets/HeatFlowMeter.png";
function plc_automation(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={plc_auto} bg_prod_img={plc_auto} heading="PLC Based Automation" text="A PROGRAMMABLE LOGIC CONTROLLER (PLC) is an industrial computer control system that continuously monitors the state of input devices and makes decisions based upon a custom program to control the state of output devices.

Almost any production line, machine function, or process can be greatly enhanced using this type of control system. However, the biggest benefit in using a PLC is the ability to change and replicate the operation or process while collecting and communicating vital information.

Another advantage of a PLC system is that it is modular. That is, you can mix and match the types of Input and Output devices to best suit your application.

We have developed controlled systems using various brands of PLC such as Siemens, Mitsubishi Electric, Schneider Electric, ABB, Delta etc."></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default plc_automation;