import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import iot_dashboard_ from "../Assets/IoTdashboard.jpg";
function Iot_dashboard(){
    return(
        <>
        <Navbar/>
        <div className="subproduct">
        <Iot_dashboard_data image={iot_dashboard_}  bg_prod_img={iot_dashboard_} heading=" IoT Dashboard" text="An IoT dashboard is the user interface within an IoT platform that enables users to monitor and interact with connected devices though graphs, charts and other tools and UI elements.Integrated Open Distro dashboards enable advanced IoT analytics visualization, analytics rules, and smart alerts."></Iot_dashboard_data>

        </div>

       


       <Footer/>
    </>
    )
    }
    export default Iot_dashboard;