import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import spcDashboard_ from "../Assets/SPCdashboard.png";

function SpcDashboard(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={spcDashboard_} bg_prod_img={spcDashboard_} heading="SPC dashboard" text="Shewhart chart, statistical process control chart. The control chart is a graph used to study how a process changes over time. Data are plotted in time order.A control chart always has a central line for the average, an upper line for the upper control limit, and a lower line for the lower control limit"></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default SpcDashboard;