import "./SubServicesStyles.css";
import"./SubServicesData";
import SubServicesData from "./SubServicesData";
import SCADA from "../Assets/SCADAsystem.png";
import industrials from "../Assets/Highspeedopto.png";
import qt_img from "../Assets/QT.png";
function HomeServices(){
    return(
        <> 
       <div className="subService">
            <h1>Our Services </h1>
            {/* <p>Qt is a widget toolkit for creating graphical user interfaces as well as </p> */}
        </div>

        <div className="subService_card">

            <SubServicesData image={SCADA} heading="SCADA System" text="" url="/scada_dashboard">
            </SubServicesData>

            <SubServicesData image={industrials} heading="High Speed Opto module" text="" url="/high_speed_opto">
            </SubServicesData>

            <SubServicesData image={qt_img} heading="QT" text="" url="/qt">
            </SubServicesData>

          </div>
        </>
    );
}
export default HomeServices