import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import industrial2 from "../Assets/Industrial4.0.jpg";
function Iot_enables(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={industrial2} heading="IoT Enabled Devices" text="IoT devices are pieces of hardware, such as sensors, actuators, gadgets, appliances, or machines, that are programmed for certain applications and can transmit data over the internet or other networks. They can be embedded into other mobile devices, industrial equipment, environmental sensors, medical devices, and more.Many of these devices are small, power- and cost-constrained microcontroller-based systems. Network bandwidth and consumer expectations around data privacy and user experience continue to demand more on-device processing, where data is processed on the IoT endpoint, rather than using cloud-based approaches."></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default Iot_enables;