import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import di_mod from "../Assets/DImodule.png";
import di_mod_full from "../Assets/RemoteDIFullImg.png";

function DI_module(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={di_mod} bg_prod_img={di_mod_full} heading="Remote Digital Input (DI) Module" text="Remote Digital Input Module with a RJ45 and Wi-Fi interface. Based on Ethernet Modbus communication using PLC/microcontroller. The digital inputs can be used for cost effective sensing of multiple digital inputs in applications, such as industrial applications, equipment status monitoring or alarm point monitoring, etc.

"></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default DI_module;