import "./SubProductStyles.css";
import { NavLink } from "react-router-dom";
function SubProductData(props){
    return(
        <>
        <div className="p-card">
            <div className="prod_image">
                <img src={props.image}alt="image"></img>
                </div>
                <h4>{props.heading}</h4>
                <p>{props.text}</p>
                <NavLink to={props.url}><button>View</button></NavLink>
             </div>
        </>
    )
}
export default SubProductData