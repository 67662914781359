import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import Qt_img2 from "../Assets/QT.png";
function Qt(){
    return(
        <>
        <Navbar/>
        <div className="subproduct">
        <Iot_dashboard_data image={Qt_img2} bg_prod_img={Qt_img2} heading="QT" text="Qt is a widget toolkit for creating graphical user interfaces as well as cross-platform applications that run on various software and hardware platforms such as Linux, Windows, macOS, Android or embedded systems with little or no change in the underlying codebase while still being a native application with native capabilities and speed.Other features include SQL database access, XML parsing, JSON parsing, thread management and network support."></Iot_dashboard_data>

        </div>

       


       <Footer/>
    </>
    )
    }
    export default Qt;