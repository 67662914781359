import { Hero } from "../componenets/Hero";
import Navbar from "../componenets/Navbar";
import ContactForm from "../componenets/ContactForm.js";
import Footer from "../componenets/Footer.js";
import contactImg from "../Assets/Contact-us-banner-1.png"; 
function Contact(){
    return(
    <>
     <Navbar/>
    <Hero cName="hero-contact" heroImg={contactImg}></Hero>

    <ContactForm></ContactForm>
    <Footer></Footer>
    </>
    )
    }
    export default Contact;