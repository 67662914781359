import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import Solenoid from "../Assets/remoteSsr.png";
import Solenoid_full_img from "../Assets/RemoteSsrFullImg.png";

function Remote_SSR(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={Solenoid} bg_prod_img={Solenoid_full_img} heading="Remote Solenoid Driver (SSR) module:" text="Using Remote Solenoid Driver easily drive load without any input side wiring. Load drive using Ethernet or RS485 or Wi-Fi. It is based on Modbus and RTU communication. Using PLC/Microcontroller it enables easy control of high-power devices and appliances for automation purposes. Perfect for DIY projects and industrial applications. It can lead to reduced cabling and thus cost savings.

"></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default Remote_SSR;