import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import Labview from "../Assets/Industrial4.0.jpg";
function Ni_labview(){
    return(
        <>
        <Navbar/>
        <div className="subproduct">
        <Iot_dashboard_data image={Labview} bg_prod_img={Labview} heading="NI Lab NI-LabVIEW" text="LabVIEW offers a graphical programming approach that helps you visualize every aspect of your application, including hardware configuration, measurement data, and debugging.This visualization makes it simple to integrate measurement hardware from any vendor, represent complex logic on the diagram, develop data analysis algorithms, and design custom engineering user interfaces."></Iot_dashboard_data>

        </div>

       


       <Footer/>
    </>
    )
    }
    export default Ni_labview;