import "./SubServicesStyles.css";
import"./SubServicesData";
import Navbar from "./Navbar";
import SubServicesData from "./SubServicesData";
import SCADA from "../Assets/SCADAsystem.png";
import industrials from "../Assets/Industrial4.0.jpg";
import qt_img from "../Assets/QT.png";
import Heat_m from "../Assets/HeatFlowMeter.png";
import Camera_v from "../Assets/CameraVisionSystem.png";
import spm from "../Assets/Spm.jpg";
import cust_dev from "../Assets/customdevop.png";
import Footer from "./Footer";

function SubServices(){
    return(
        <>
       <Navbar></Navbar> 
        <div className="subService">
            <h1>Our Services </h1>
            {/* <p>Qt is a widget toolkit for creating graphical user interfaces as well as </p> */}
        </div>

        <div className="subService_card">

            <SubServicesData image={SCADA} heading="SCADA System" text="" url="/scada_dashboard">
            </SubServicesData>

            <SubServicesData image={industrials} heading="NI Lab NI-LabVIEW" text="" url="/ni_labview">
            </SubServicesData>

            <SubServicesData image={qt_img} heading="QT" text="" url="/qt">
            </SubServicesData>

          </div>

          <div className="subService_card">
          <SubServicesData image={Heat_m} heading="PLC Based Automation" text="" url="/plc_automation">
            </SubServicesData>

            <SubServicesData image={Camera_v} heading="Camera VISION Systems" text="" url="/camera_vi">
            </SubServicesData>

            <SubServicesData image={spm} heading="Special Purpose Machine (SPM" text="" url="/spm">
            </SubServicesData>

          </div>

          <div className="subService_card">
          <SubServicesData image={cust_dev} heading="Custom Development" text="" url="/custom_dev">
            </SubServicesData>

            <SubServicesData image={industrials} heading="IoT Enabled Devices" text="" url="/iot_enables">
            </SubServicesData>

            <SubServicesData image={spm} heading="Special Purpose Machine (SPM" text="" url="/spm">
            </SubServicesData>

          </div>

<Footer></Footer>
        </>
    );
}
export default SubServices