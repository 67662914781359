import "./HeroStyles.css"


export function Hero(props) {
    return(
        <>
       <div className={props.cName}>
        <img alt="image" src={props.heroImg}></img>

        <div className="hero-text">
            <p>{props.text}</p>
            <h1>{props.title}</h1>

        </div>
       </div>
        </>
    )
}