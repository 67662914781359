import Navbar from "./Navbar";
import Footer from "./Footer";
import Iot_dashboard_data from "./Iot_dashboard_data";
import industrial_ from "../Assets/Industrial4.0.jpg";
function IIot_data(){
    return(
        <>
        <Navbar/>
        <Iot_dashboard_data image={industrial_} bg_prod_img={industrial_} heading="Industrial Internet of Things (IIoT) Monitoring" text="connects your people, products, and processes to power digital transformation. Using industrial IoT platforms, companies connect, monitor, analyze, and act on data in new ways.You can start fast with digital transformation,to how they create value and engage with customers."></Iot_dashboard_data>
       <Footer/>
    </>
    )
    }
    export default IIot_data
;